<template>
  <div class="included-markets__root">
    <div class="search-container">
      <TextInput
        v-model="search"
        placeholder="Search markets"
        icon="search"
        small
      />
    </div>
    <div
      class="markets-container__scrolbar"
      ref="marketsContainerScroll"
      @scroll="onMarketsScroll"
    >
      <div
        v-for="marketGroup in filteredMarketGroups"
        :key="marketGroup.groupCode"
        class="market-group__container"
      >
        <p class="group-item">
          {{ marketGroup.groupName }}
        </p>
        <div
          v-for="market in findMarketsByGroup(marketGroup.groupCode)"
          :key="market.marketTemplateId"
          class="market-item"
          @mouseover="setHoveredMarket(market.marketTemplateId)"
          @mouseleave="setHoveredMarket(null)"
        >
          <div class="market-info">
            <p class="market-name">
              {{ market.name }}
            </p>
            <p
              v-if="!isNil(market.enabled)"
              :class="['market-status ', getMarketTemplateStatusType(market.marketTemplateId)]"
            >
              {{ formatMarketTemplateStatusType(getMarketTemplateStatusType(market.marketTemplateId)) }}
            </p>
          </div>
          <Dropdown
            v-if="isMarketHovered(market)"
            :class="['market-item-more-dropdown ']"
            icon="more-dots"
            hide-chevron
            right
          >
            <DropdownItem
              @click="configureMarket(market)"
              clickable
            >
              Configure
            </DropdownItem>

            <DropdownItem
              @click="toggleMarketEnabled(market)"
              clickable
              :disabled="isNil(market.enabled)"
            >
              {{ market.enabled ? 'Disable' : 'Enable' }}
            </DropdownItem>

            <DropdownItem
              class="dropdown-item--danger"
              @click="removeMarket(market)"
              clickable
            >
              Remove
            </DropdownItem>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import {
  uniqBy, filter, reduce, map, includes,
  toLower, isNil, flatMap, every, some, debounce,
} from 'lodash';
import TextInput from '@/components/common/TextInput';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';

export default {
  components: {
    TextInput,
    Dropdown,
    DropdownItem,
  },
  props: {
    markets: {
      type: Array,
      default: () => [],
    },
    marketGroups: {
      type: Array,
      default: () => [],
    },
    originalSportTemplate: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['onConfigureMarket', 'onRemoveMarket', 'onMarketEnabledToggled'],
  setup(props, { emit }) {
    const store = useStore();
    const search = ref('');
    const hoveredMarket = ref();
    const marketsTopScrollPosition = computed(() => store.getters.marketTemplateMarketsScrollPosition || 0);
    const uniqueMarketTemplates = computed(() => uniqBy(props.markets, 'marketTemplateId'));
    const getMarketsByCode = (marketCode) => filter(uniqueMarketTemplates.value, { marketCode });
    const marketsWithGroups = computed(() => flatMap(props.marketGroups, (group) => (reduce(
      group.markets.nodes,
      (allMarkets, market) => [
        ...allMarkets,
        ...map(getMarketsByCode(market.marketCode), (m) => ({
          ...m,
          groupName: group.displayName,
          groupCode: group.code,
        })),
      ], [],
    ))));
    const marketsWithoutGroups = computed(() => {
      const marketCodesWithGroups = map(marketsWithGroups.value, 'marketTemplateId');
      const withoutGroups = filter(props.markets, (market) => !includes(marketCodesWithGroups, market.marketTemplateId));
      return map(withoutGroups, (market) => ({
        ...market,
        groupCode: 'UNGROUPED',
        groupName: 'Ungrouped',
      }));
    });
    const allMarkets = computed(() => uniqBy([
      ...marketsWithGroups.value,
      ...marketsWithoutGroups.value,
    ], 'marketTemplateId'));
    const filteredMarkets = computed(() => filter(
      allMarkets.value,
      (market) => (
        includes(toLower(market.name), toLower(search.value))
        || includes(toLower(market.groupName), toLower(search.value))
      ),
    ));
    const filteredMarketGroups = computed(() => uniqBy(map(filteredMarkets.value, ({ groupName, groupCode }) => ({ groupName, groupCode })), 'groupCode'));
    const findMarketsByGroup = (code) => filter(filteredMarkets.value, { groupCode: code });
    const setHoveredMarket = (marketTemplateId) => { hoveredMarket.value = marketTemplateId; };
    const isMarketHovered = (market) => hoveredMarket.value === market.marketTemplateId;

    const configureMarket = (market) => emit('onConfigureMarket', market);
    const toggleMarketEnabled = (market) => { emit('onMarketEnabledToggled', market, !market.enabled); };
    const removeMarket = (market) => {
      emit('onRemoveMarket', [{ ...market, included: false }]);
    };

    const getMarketTemplateStatusType = (marketTemplateId) => {
      const templates = filter(props.markets, { marketTemplateId });
      const isEveryEnabled = every(templates, { enabled: true });
      const isSomethingEnabled = some(templates, { enabled: true });
      if (isEveryEnabled) return 'ENABLED';
      if (isSomethingEnabled) return 'PARTIALLY_ENABLED';
      return 'DISABLED';
    };
    const formatMarketTemplateStatusType = (statusType) => {
      if (statusType === 'ENABLED') return 'Enabled';
      if (statusType === 'PARTIALLY_ENABLED') return 'Partially enabled';
      return 'Disabled';
    };

    const marketsContainerScroll = ref(null);

    const debounceSaveScrollPosition = debounce((scrollTop) => {
      store.dispatch('saveMarketTemplateMarketsScrollPosition', scrollTop);
    }, 500);

    const onMarketsScroll = () => {
      const { scrollTop } = marketsContainerScroll.value;
      debounceSaveScrollPosition(scrollTop);
    };

    const scrollToMarketsHeight = () => {
      marketsContainerScroll.value.scrollTo({
        top: marketsTopScrollPosition.value || 0,
        behavior: 'smooth',
      });
    };

    onMounted(() => {
      scrollToMarketsHeight();
    });

    return {
      isNil,
      search,
      marketsWithGroups,
      marketsWithoutGroups,
      filteredMarkets,
      filteredMarketGroups,
      hoveredMarket,
      setHoveredMarket,
      isMarketHovered,
      toggleMarketEnabled,
      removeMarket,
      configureMarket,
      getMarketsByCode,
      findMarketsByGroup,
      getMarketTemplateStatusType,
      formatMarketTemplateStatusType,
      marketsContainerScroll,
      onMarketsScroll,
    };
  },
};
</script>
<style lang="scss">
.included-markets__root {
  padding: 13px 0;
  height: 100%;

  .search-container {
    height: 60px;
  }

  .markets-container__scrolbar {
    height: calc(100% - 73px);
    overflow-y: auto;

    .market-group__container {
      margin-bottom: 30px;

      .group-item {
        margin-bottom: 15px;
      }
    }

    .market-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0 8px 12px;
      border-left: 1px solid $gray400;

      .market-name {
        font-size: 14px;
        color: $black;
      }
      .market-status {
        font-size: 12px;
        color: $gray700;
        &.PARTIALLY_ENABLED {
          color: #ffc531;
        }
        &.DISABLED {
          color: $error500;
        }
      }

      &:hover .market-item-more-dropdown {
        display: block;
      }
      .market-item-more-dropdown {
        display: none;
        position: relative;
        z-index: 1;

        .button {
          background-color: transparent;
          border: none;
          outline: none;
          box-shadow: none;
        }
        .dropdown-item--danger {
          color: $error500;
        }
      }
    }
  }
}
</style>
